<template>
  <validationObserver
    ref="createStaffForm"
    v-slot="{ handleSubmit }"
    slim
  >
    <b-form
      @submit.prevent="handleSubmit(createFeedBack)"
    >
      <div class="text-center m-5 main-div">
        <b-img
          v-if="!loading"
          class="logo mb-1"
          :src="courier.company_logo ? `${bucketUrl}${courier.company_logo}` : defaultCompanyLogo"
          alt="logo"
        />
        <h5>Customer Feedback For {{ orderData.waybill_number }}</h5>
        <star-rating
          :rating="5"
          :max-rating="5"
          :star-size="40"
          @rating-selected="setRating"
        />
        <b-form-textarea
          id="textarea"
          v-model="form.description"
          :state="form.description.length < maxChar"
          class="mt-2"
          :class="form.description.length >= maxChar ? 'text-danger' : ''"
          :maxlength="maxChar"
          placeholder="Enter Your Comment"
          rows="3"
        />
        <small
          class="textarea-counter-value float-right"
          :class="form.description.length >= maxChar ? 'bg-danger' : ''"
        >
          <span class="char-count">{{ form.description.length }}</span> / {{ maxChar }}
        </small>
        <b-button
          class="save mt-1 bg-success"
          type="submit"
        >
          Save
        </b-button>
      </div>
    </b-form>
  </validationObserver>
</template>

<script>
/* eslint-disable global-require */
import {
  BButton, BFormTextarea, BForm, BImg,
} from 'bootstrap-vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import StarRating from 'vue-star-rating'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { bucketUrl, defaultCompanyLogo } from '@/constants/config'

const FeedBackRepository = RepositoryFactory.get('feedback')
const BusinessRepository = RepositoryFactory.get('businessSetting')

export default {
  components: {
    BButton,
    BFormTextarea,
    StarRating,
    BForm,
    BImg,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      loading: true,
      orderData: {},
      meta: {},
      rating: 5,
      form: {
        description: '',
      },
      bucketUrl,
      defaultCompanyLogo,
      maxChar: 250,
    }
  },
  computed: {
  },
  async mounted() {
    await this.getOrderDetailsByCode()
    await this.fetchBusinessSettings()
  },
  methods: {
    async fetchBusinessSettings() {
      try {
        this.loading = true
        this.courier = (await BusinessRepository.getbusinessPublicSettingsResource()).data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getOrderDetailsByCode() {
      try {
        const fullUrl = this.$route.fullPath
        const code = fullUrl.split('rate-us/')[1]
        const { data } = (await FeedBackRepository.getOrderDetailsByCode(code))
        this.orderData = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    setRating: function c(rating) {
      this.rating = rating
    },
    async createFeedBack() {
      this.form.rating = this.rating
      try {
        const fullUrl = this.$route.fullPath
        const code = fullUrl.split('rate-us/')[1]
        await FeedBackRepository.saveFeedbackByCode(code, this.form)
        this.courier = (await BusinessRepository.getbusinessPublicSettingsResource()).data.data
        this.showSuccessMessage(`Thank you for Rating (${this.courier.company_name})`)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>

<style lang="scss">
  .save{
    background-color: #28a745 !important;
    color: white !important;
  }
  .vue-star-rating{
    display: inline-block !important;
  }
  .vue-star-rating-rating-text{
    display: none !important;
  }
  .main-div{
    border: 3px solid #4285f5;
    padding: 10px;
  }
  .hidden{
    display: block !important;
  }
  .logo{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 15vw;
    border-radius: 50%;
  }
</style>
